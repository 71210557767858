import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import "./Header.css"

const Header = ({ siteTitle }) => (
  <div id="nav-wrapper">
    <div className="top-bar">
      <div className="top-bar-container">
        <div className="top-bar-row">
          <div className="top-bar-item">
            <Link to="/">
              Home
            </Link>
          </div>
          <div className="top-bar-spring" />
          <div className="top-bar-item">
            <Link to="/blog">
              Blog
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
