import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faLinkedinIn,
  faTwitter,
  faMediumM,
} from "@fortawesome/free-brands-svg-icons"

const PROJECTS = [
  {
    name: "Pair Code",
    description:
      "A real-time collaboration programming web application. This allows multiple users write code on one editor",
    github: "https://github.com/akinshonibare/code-pair",
  },
  {
    name: "Slack Clone",
    description:
      "An online team collaboration portal made after the popular slack application",
    github: "https://github.com/akinshonibare/slack-clone",
  },
  {
    name: "MusiCrowd",
    description: "The Crowdsourced Spotify DJ",
    github: "https://github.com/ccrowley96/MusiCrowd",
  },
  {
    name: "Quiz me",
    description: "Generates variations of Questions based of text input",
    github: "https://devpost.com/software/quiz-me-xyidbt",
  },
  {
    name: "Shopify-Design-Intern-Challenge 2019",
    description:
      "Search for waste items using the Toronto Waste Wizard database",
    github:
      "https://github.com/akinshonibare/Shopify-Design-Engineer-Challenge",
  },
  {
    name: "MERN Boilerplate",
    description: "Full stack Starter Boilerplate",
    github: "https://github.com/akinshonibare/mern-boilerplate",
  },
  {
    name: "Movie Ticketing Web App",
    description: "Movie Ticketing System PHP / SQL Web App",
    github: "https://github.com/akinshonibare/Movie-Ticketing-PHP-WebApp",
  },
  {
    name: "Canvas Drawing Web App",
    description: "Online Web application drawing tool",
    github: "https://github.com/akinshonibare/canvas-drawing-app",
  },
]

const EXPERIENCE = [
  {
    name: "Software Engineer - Shopify",
  },
  {
    name: "Director of Technology - QHacks",
  },
  {
    name: "Developer Student Club Lead - Queen's University",
  },
  {
    name: "Software Developer Intern - Centre for Advanced Computing",
  },
  {
    name: "Front-End Developer - Bounced Inc.",
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home_wrapper">
      <div className="home_content">
        <div className="home_details grey_border">
          <div className="name">
            <h1>Akin Shonibare</h1>
          </div>
          <div className="tags">
            <span>Software Engineer @ Shopify</span>
          </div>
          <div className="social_media">
            <a
              href="https://www.linkedin.com/in/akinshonibare/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
            </a>
            <a
              href="https://github.com/akinshonibare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
            <a
              href="https://medium.com/@akinshonibare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faMediumM} size="2x" />
            </a>
            <a
              href="https://twitter.com/akiin_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </div>
        </div>
        <div className="projects_wrapper">
          <h2>Projects</h2>
          <p>
            With my experience in Javascript, Java, Python and C++ I have
            created some things
          </p>
          <div className="projects">
            <ul>
              {PROJECTS.map(item => (
                <li>
                  {item.name} <span>&#183;</span> {item.description}{" "}
                  <span>&#183;</span>{" "}
                  <a
                    href={item.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="projects_wrapper">
          <h2>Experience</h2>
          <p>My experience and activities</p>
          <div className="projects">
            <ul>
              {EXPERIENCE.map(item => (
                <li>{item.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
